import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./ImageCardStyles.scss";

const ImagesCard = ({ images }) => {
  return (
    <div className="w-[100%] h-[560px]">
      <Swiper navigation={true} className="mySwiper">
        {!images || images?.length === 0 ? (
          <div className="flex justify-center items-center h-full">
            <p className="flex justify-center items-center py-8">
              No images available
            </p>
          </div>
        ) : (
          images?.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image?.image} alt="image" />
            </SwiperSlide>
          ))
        )}
      </Swiper>
    </div>
  );
};

export default ImagesCard;
