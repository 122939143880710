import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  MarkerF,
  Polygon,
} from "@react-google-maps/api";
import config from "../../../configs/api";

function VerificationMap(props) {
  // Set default center to Lagos, Nigeria
  const [center, setCenter] = useState({ lat: 6.5244, lng: 3.3792 });
  const [mapInstance, setMapInstance] = useState(null);

  // Swap lat and lng in the data processing
  const data = props.points?.map((item) => ({
    lat: parseFloat(item?.lng),
    lng: parseFloat(item?.lat)
  }));

  // Fit bounds to show all markers when data changes
  useEffect(() => {
    if (mapInstance && data?.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      data?.forEach((point) => {
        bounds?.extend(new window.google.maps.LatLng(point?.lat, point?.lng));
      });
      mapInstance?.fitBounds(bounds);
    }
  }, [mapInstance, data]);

  const onMapLoad = (map) => {
    setMapInstance(map);
  };

  return (
    <LoadScript googleMapsApiKey={config.mapsKey}>
      <GoogleMap
        mapTypeId="satellite"
        center={data?.length ? data[0] : center}
        mapContainerStyle={{ height: props.height ? props.height : "60vh" }}
        zoom={15}
        onLoad={onMapLoad}
      >
        {/* Add markers for each point */}
        {data.map((point, index) => (
          <MarkerF
            key={index}
            position={point}
            label={(index + 1).toString()}
          />
        ))}

        {/* Add polygon if there are at least 3 points */}
        {data.length >= 3 && (
          <Polygon
            path={data}
            options={{
              fillColor: "#009688",
              fillOpacity: 0.4,
              strokeColor: "#000",
              strokeOpacity: 1,
              strokeWeight: 1,
            }}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
}

export default VerificationMap;

// AIzaSyAE5beByDMsRgJwMDLutqutRI_ZjxnyCJs
